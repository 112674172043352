import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const PaginationWrap: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  ".MuiButtonBase-root-MuiIconButton-root:hover": {
    backgroundColor: "transparent",
  },
  ".MuiTablePagination-root": {
    color: theme.palette.grey[600],
    fontSize: theme.typography.htmlFontSize,
    fontFamily: theme.typography.fontFamily,
  },
  ".MuiTablePagination-select:focus": {
    backgroundColor: "transparent",
  },
  ".MuiTablePagination-selectIcon": {
    top: "calc(35% - 0.5em)",
    left: 24,
  },
}));
