export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z]).{6,35}$/;
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NUMBER_LENGTH_REGEX = /^.{1,15}$/;
export const CONTACT_NUMBER_REGEX = /^.{6,15}$/;
export const ALPHABETS_REGEX = /^[A-Za-z ]+$/;
export const NUMBERS_REGEX = /^[0-9]*$/;
export const WHITE_SPACE_REGEX = /^(?=\s*\S)/;
export const RE_DIGIT = new RegExp(/^\w+$/);
export const ZIP_CODE_REGEX = /^.{1,5}$/;
export const LENGTH_REGEX = (length) => new RegExp(`^.{1,${length}}$`);
export const TEMPLATE_NAME_REGEX =  /^[a-zA-Z0-9_ ]*$/