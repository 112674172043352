import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const DualSliderWrap: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  width: "93%",
  margin: "auto",
  display: "table",
}));
