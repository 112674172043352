import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import { ISnackbarProps } from "./Snackbar.types";
import { styled } from "@mui/material/styles";
import clsx from "clsx";

const CustomToast = styled(Snackbar, {
  // shouldForwardProp: props => isPropValid(props),
})<Partial<ISnackbarProps>>(({ theme }) => ({
  "&.u-toast": {
    // ".MuiSnackbarContent-root": {
    //   padding: "8px 16px",
    //   borderRadius: theme.shape.borderRadius,
    //   border: "1px solid" + theme.palette.common.black,
    //   backgroundColor: theme.palette.grey[900],
    //   textAlign: "center",
    //   boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.16)",
    //   fontSize: theme.typography.body2.fontSize,
    //   lineHeight: theme.typography.body1.lineHeight,
    //   fontWeight: theme.customVariables.fontWeightSemiBold,
    //   color: theme.palette.common.white,
    //   [theme.breakpoints.up("sm")]: {
    //     minWidth: "auto",
    //   },
    //   ".MuiSnackbarContent-message": {
    //     padding: 0,
    //     width: "100%",
    //   },
    // },
    // "&.u-toast-error": {
    //   ".MuiSnackbarContent-root": {
    //     backgroundColor: theme.palette.error.main,
    //     borderColor: theme.palette.error.main,
    //   },
    // },
    // "&.u-toast-success": {
    //   ".MuiSnackbarContent-root": {
    //     backgroundColor: theme.palette.success.main,
    //     borderColor: theme.palette.success.main,
    //   },
    // },
    // "&.u-toast-info": {
    //   ".MuiSnackbarContent-root": {
    //     backgroundColor: theme.palette.info.main,
    //     borderColor: theme.palette.info.main,
    //   },
    // },
    // "&.u-toast-warning": {
    //   ".MuiSnackbarContent-root": {
    //     backgroundColor: theme.palette.warning.main,
    //     borderColor: theme.palette.warning.main,
    //   },
    // },
    // Permanent Toast Style
    ".MuiPaper-root": {
      padding: "8px 16px",
      borderRadius: theme.shape.borderRadius,
      border: "1px solid" + theme.palette.common.black,
      backgroundColor: theme.palette.grey[900],
      textAlign: "center",
      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.16)",

      [theme.breakpoints.up("sm")]: {
        minWidth: "auto",
      },
      ".MuiAlert-message": {
        padding: 0,
        width: "100%",
        marginRight: "8px",
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body1.lineHeight,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.common.white,
      },
      ".MuiAlert-icon": {
        display: "none",
      },
      ".MuiAlert-action": {
        color: theme.palette.common.white,
        padding: 0,
        ".MuiButtonBase-root": {
          margin: 0,
          padding: 0,
          ".MuiSvgIcon-root": {
            width: "24px",
            height: "24px",
          },
        },
      },
    },
    "&.u-toast-error": {
      ".MuiPaper-root": {
        backgroundColor: theme.palette.error.main,
        borderColor: theme.palette.error.main,
      },
    },
    "&.u-toast-success": {
      ".MuiPaper-root": {
        backgroundColor: theme.palette.success.main,
        borderColor: theme.palette.success.main,
      },
    },
    "&.u-toast-info": {
      ".MuiPaper-root": {
        backgroundColor: theme.palette.info.main,
        borderColor: theme.palette.info.main,
      },
    },
    "&.u-toast-warning": {
      ".MuiPaper-root": {
        backgroundColor: theme.palette.warning.main,
        borderColor: theme.palette.warning.main,
      },
    },
  },
}));

const CustomSnackbar: React.FC<ISnackbarProps> = ({
  theme,
  children,
  variant,
  ...props
}) => {
  return (
    <CustomToast
      className={clsx({
        "u-toast": true,
        "u-toast-error": variant === "error",
        "u-toast-success": variant === "success",
        "u-toast-info": variant === "info",
        "u-toast-warning": variant === "warning",
      })}
      theme={theme}
      {...props}
    >
      {children}
    </CustomToast>
  );
};
export default CustomSnackbar;
