import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const DuoTabWrap: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  ".tab-list-underline": {
    cursor: "auto !important",
  },
  "&.tab-form": {
    ".tab-list-underline": {
      marginBottom: "5px",
      ".TabUnstyled-root": {
        "&.error": {
          "&::before": {
            content: '"*"',
            position: "absolute",
            right: "-8px",
            color: theme.palette.error.main,
          },
        },
      },
    },
  },
}));
