import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const TheadHiddenScroll: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  width: "100%",
  display: "inline-block",
  position: "relative",
  ".scroll-hide": {
    backgroundColor: theme.palette.common.white,
    borderBottom: "1px solid" + theme.palette.divider,
    position: "absolute",
    width: 7,
    height: 100,
    right: 1,
    top: 1,
    display: "block",
    borderRadius: "0 8px 0 0",

    "&.spr-border": {
      "&::after": {
        display: "inline-block",
        content: `''`,
        backgroundColor: theme.palette.divider,
        width: 7,
        height: 1,
        position: "absolute",
        top: "50%",
      },
    },

    "&.sh-fixed-cell": {
      height: 100.5,
      top: 0.5,
    },
  },
  ".u-table": {
    ".u-table-head": {
      "th, td": {
        height: 50,
        "&.action-cell.fixed-cell": {
          right: "-1px !important",
        },
      },
    },
  },
  "&.shw-fixed-cell": {
    ".u-table": {
      ".u-table-body, .u-table-head": {
        "th, td": {
          "&.action-cell.fixed-cell": {
            right: "-1px !important",
          },
        },
      },
    },
  },
}));
