import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTheme } from "@mui/material";
import { FC } from "react";
import { styled } from "@mui/material/styles";
import { IPhoneInput } from "./PhoneInput.types";
import { Box, IBoxProps, Typography } from "@ntpkunity/controls";
const FlagDropdowArrow = `url('data:image/svg+xml;utf8,<svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 0.5L4 3.5L7 0.5" stroke="${encodeURIComponent(
  "#696C80"
)}" stroke-linecap="round" stroke-linejoin="round"/></svg>')`;

export const ContactInput = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.u-phone-input": {
    position: "relative",
    ".react-tel-input": {
      fontFamily: theme.typography.fontFamily,
      ".form-control": {
        width: "100%",
        height: 48,
        borderRadius: theme.shape.borderRadius,
        lineHeight: theme.typography.body2.lineHeight,
        fontSize: theme.typography.body1.fontSize,
        padding: "15px 16px 12px 45px",
        color: "rgba(0,0,0,0.87)",
        borderColor: "rgba(0, 0, 0, 0.23)",
        "&:hover": {
          borderColor: theme.palette.primary.main,
        },
        "&:focus": {
          borderColor: theme.palette.primary.main,
          borderWidth: 2,
          "&::placeholder": {
            opacity: 0.26,
          },
        },
        "&::placeholder": {
          opacity: 0,
        },
      },
      ".flag-dropdown": {
        top: "50%",
        padding: 0,
        bottom: "auto",
        transform: "translateY(-50%)",
        left: 16,
        backgroundColor: "transparent",
        border: "none",
        borderRadius: 0,
      },
      ".selected-flag": {
        width: "auto",
        padding: 0,
        borderRadius: 0,
        ".flag": {
          position: "relative",
          top: "auto",
          marginTop: 4,
          filter: "drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.1))",
          borderRadius: "1.5px",
        },
        ".arrow": {
          marginTop: -2,
          left: 24,
          width: 8,
          height: 4,
          borderLeft: "none",
          borderRight: "none",
          borderTop: "none",
          top: 4,
          backgroundImage: FlagDropdowArrow,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          "&.up": {
            border: "none",
          },
        },
      },
      ".country-list": {
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: theme.palette.grey[50],
        boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.16)",
        minWidth: 145,
        padding: 8,
        color: theme.palette.grey[600],
        scrollbarWidth: "thin",
        borderRadius: theme.shape.borderRadius,
        top: 31,
        left: -15,
        margin: 0,
        "&::-webkit-scrollbar": {
          width: 7,
          height: 7,
        },
        "&::-webkit-scrollbar-track": {
          padding: "0 1px",
          backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
          transition: "all 0.3s",
          backgroundColor: "#cdcdcd",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#a6a6a6",
        },
        li: {
          padding: "12px 24px 12px 16px",
          borderRadius: theme.shape.borderRadius,
          fontWeight: theme.typography.fontWeightRegular,
          "&:hover": {
            backgroundColor: "#F8F8F8",
            color: theme.palette.grey[900],
            fontWeight: theme.typography.fontWeightMedium,
          },
        },
      },
    },
    ".u-input-label": {
      color: "rgba(0,0,0,0.87)",
      fontFamily: theme.typography.fontFamily,
      lineHeight: theme.typography.body1.lineHeight,
      transform: "translate(10px, 12px) scale(1)",
      padding: "0 5px 0 5px",
      backgroundColor: theme.palette.common.white,
      transition:
        "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      transformOrigin: "top left",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      position: "absolute",
      left: 0,
      top: 0,
      maxWidth: "calc(100% - 24px)",
      pointerEvents: "none",
      userSelect: "none",
    },
    "&.u-input-focus": {
      ".u-input-label": {
        color: theme.palette.text.secondary,
        transform: "translate(10px, -9px) scale(0.85)",
      },
      "&.u-input-error": {
        ".u-input-label": {
          color: theme.palette.error.main,
        },
        ".form-control": {
          borderColor: theme.palette.error.main,
          borderWidth: 2,
        },
      },
    },
    "&.u-input-filled": {
      ".u-input-label": {
        color: theme.palette.text.secondary,
        transform: "translate(10px, -9px) scale(0.85)",
      },
    },
    "&.u-input-error": {
      ".u-input-label": {
        color: theme.palette.error.main,
      },
      ".form-control": {
        borderColor: theme.palette.error.main,
        borderWidth: 2,
      },
      "&.u-input-filled": {
        ".u-input-label": {
          color: theme.palette.error.main,
          transform: "translate(10px, -9px) scale(0.85)",
        },
        ".form-control": {
          borderColor: theme.palette.error.main,
          borderWidth: 2,
        },
      },
    },
  },
}));
const Component: FC<IPhoneInput> = (props) => {
  const theme = useTheme();

  return (
    <ContactInput theme={theme} className="u-phone-input u-input-filled">
      <PhoneInput theme={theme} {...props} />
      <Typography
        className="u-input-label"
        theme={theme}
        variant="body1"
        component="p"
      >
        {props.inputLabel}
      </Typography>
    </ContactInput>
  );
};

export default Component;
